import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdentificationType, TargetListResponse as RequestListResponse } from '@ydistri/api-sdk';
import { RequestListItemsCompared, RequestListRow } from './requestListsTypes';
import { ActiveScreenType } from '../common/administrationItemsLib';
import { AdministrationItemListHandlingMethod } from '../common/administrationItemsTypes';

export interface RequestListAdministrationState {
  selectedRequestList?: RequestListResponse;
  handlingMethod?: AdministrationItemListHandlingMethod;
  productItemsContent: string;
  productItemsErrors: string;
  productIdentificationType: IdentificationType;
  warehouseIdentificationType: IdentificationType;
  showArchivedItems: boolean;
  sortField?: string;
  activeScreenType: ActiveScreenType;
  parsedRequestListItems: RequestListRow[];
  isLoading: boolean;
  isOperationFinishedWithSuccess?: boolean;
  validation: RequestListItemsCompared | undefined;
  refreshRequestListContentFlip: boolean;
}

export interface ValidationFinishedPayload {
  validation: RequestListItemsCompared | undefined;
  errors?: string;
}

const initialState: RequestListAdministrationState = {
  selectedRequestList: undefined,
  handlingMethod: undefined,
  productItemsContent: '',
  productItemsErrors: '',
  productIdentificationType: IdentificationType.CustomerId,
  warehouseIdentificationType: IdentificationType.CustomerId,
  showArchivedItems: false,
  sortField: 'LastUpdatedAt',
  activeScreenType: ActiveScreenType.NO_SELECTION,
  parsedRequestListItems: [],
  isLoading: false,
  isOperationFinishedWithSuccess: undefined,
  validation: undefined,
  refreshRequestListContentFlip: false,
};

const appendValidationHelper = (
  data: RequestListItemsCompared | undefined,
  currentValidation: RequestListItemsCompared | undefined,
) => {
  if (currentValidation) {
    return {
      ...currentValidation,
      items: currentValidation.items
        .filter(validatedItem => !validatedItem.isError)
        .filter(validatedItem => {
          const foundIndex = data?.items.findIndex(item => {
            return (
              validatedItem.inputData.productId === item.inputData.productId &&
              validatedItem.inputData.storeId === item.inputData.storeId
            );
          });
          return foundIndex === -1;
        })
        .concat(data?.items ?? []),
    };
  } else {
    return data;
  }
};

export const requestListAdministrationSlice = createSlice({
  name: 'requestListAdministration',
  initialState,
  reducers: {
    setSelectedRequestList: (state, action: PayloadAction<RequestListResponse | undefined>) => {
      const previousSelectedRequestListId = state.selectedRequestList?.targetListId;
      state.selectedRequestList = action.payload;

      //reset previous state if any
      state.validation = undefined;
      state.parsedRequestListItems = [];
      state.productItemsContent = '';
      state.productItemsErrors = '';

      //if request list is selected, we are in detail screen
      //if no selection is made, we are in no selection screen
      if (action.payload) {
        //we may be updating a currently selected request list
        //if so, we do not want to change the screen type
        if (action.payload.targetListId !== previousSelectedRequestListId) {
          state.activeScreenType = ActiveScreenType.DETAIL;
        }
      } else {
        state.activeScreenType = ActiveScreenType.NO_SELECTION;
      }
    },

    setHandlingMethod: (
      state,
      action: PayloadAction<AdministrationItemListHandlingMethod | undefined>,
    ) => {
      state.handlingMethod = action.payload;
    },
    setProductItemsError: (state, action: PayloadAction<string>) => {
      state.productItemsErrors = action.payload;
    },
    setProductItemsContent: (state, action: PayloadAction<string>) => {
      state.productItemsContent = action.payload;
    },
    addProductItemsContent: (state, action: PayloadAction<string>) => {
      state.productItemsContent += action.payload;
    },
    setIdentificationType: (state, action: PayloadAction<IdentificationType>) => {
      state.productIdentificationType = action.payload;
    },
    setWarehouseIdentificationType: (state, action: PayloadAction<IdentificationType>) => {
      state.warehouseIdentificationType = action.payload;
    },
    setShowArchivedItems: (state, action: PayloadAction<boolean>) => {
      state.showArchivedItems = action.payload;
    },
    setSortField: (state, action: PayloadAction<string>) => {
      state.sortField = action.payload;
    },
    setActiveScreenType: (state, action: PayloadAction<ActiveScreenType>) => {
      state.activeScreenType = action.payload;
    },
    setRequestListItems: (state, action: PayloadAction<RequestListRow[]>) => {
      state.parsedRequestListItems = action.payload;
    },

    resetRequestListItems: state => {
      state.parsedRequestListItems = [];
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsOperationFinishedWithSuccess: (state, action: PayloadAction<boolean | undefined>) => {
      state.isOperationFinishedWithSuccess = action.payload;
    },
    setValidation: (state, action: PayloadAction<RequestListItemsCompared | undefined>) => {
      state.validation = action.payload;
    },
    appendValidation: (state, action: PayloadAction<RequestListItemsCompared | undefined>) => {
      state.validation = appendValidationHelper(action.payload, state.validation);
    },
    clearValidation: state => {
      state.validation = undefined;
      state.parsedRequestListItems = [];
      state.productItemsContent = '';
      state.productItemsErrors = '';
    },
    refreshRequestListContent: state => {
      state.refreshRequestListContentFlip = !state.refreshRequestListContentFlip;
    },
    validationFinished: (state, action: PayloadAction<ValidationFinishedPayload>) => {
      state.validation = appendValidationHelper(action.payload.validation, state.validation);
      state.activeScreenType = ActiveScreenType.VALIDATION;
      state.productItemsErrors = action.payload.errors ?? '';
      state.productItemsContent = '';
    },
    resetState: () => initialState,
  },
});

export const {
  setSelectedRequestList,
  setHandlingMethod,
  setProductItemsContent,
  setIdentificationType,
  setWarehouseIdentificationType,
  setProductItemsError,
  setShowArchivedItems,
  setSortField,
  setActiveScreenType,
  setRequestListItems,
  resetRequestListItems,
  setIsOperationFinishedWithSuccess,
  setIsLoading,
  setValidation,
  clearValidation,
  refreshRequestListContent,
  resetState,
  addProductItemsContent,
  appendValidation,
  validationFinished,
} = requestListAdministrationSlice.actions;

export const requestListAdministrationReducer = requestListAdministrationSlice.reducer;
