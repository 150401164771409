import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SkuAttrsStored } from '../../lib/sku/skuTypes';
import { SkuResponse } from '@ydistri/api-sdk';
import { parseSkuAttributes } from '../../lib/sku/skuLib';
import { makeSKUAttributesStorable } from './detailLib';
import { NO_VALUE } from '../../lib/utils/utilsTypes';
import { Units } from '../../lib/charts/saleChartsTypes';

export interface DetailChartSettings {
  unit: Units;
  skuChartUnit: Units;
  forecastConfidence: number[];
  showCumulativeForecast: boolean;
}

export interface DetailSliceState {
  productId: number;
  storeId: number;
  skuId: number;
  skuEntity?: SkuAttrsStored; //Date object is not serializable in Redux, so we store the timestamp instead
  regionId: number;
  isLoadingSalesData: boolean;
  isDetailBusy: boolean;
  /* Indicator of an unsuccessful SKU search */
  skuNotFound?: boolean;

  /* when SKU search is not successful, this contains the searched id */
  skuSearchId?: number;
  chartSettings: DetailChartSettings;
}

const initialState: DetailSliceState = {
  productId: NO_VALUE,
  storeId: NO_VALUE,
  skuId: NO_VALUE,
  regionId: NO_VALUE,
  isLoadingSalesData: false,
  isDetailBusy: false,
  chartSettings: {
    unit: Units.VALUE,
    skuChartUnit: Units.QUANTITY,
    forecastConfidence: [75],
    showCumulativeForecast: false,
  },
};

const resetSkuNotFoundState = (state: DetailSliceState) => {
  state.skuNotFound = false;
  state.skuSearchId = undefined;
};

export const detailSlice = createSlice({
  name: 'detailReducer',
  initialState,
  reducers: {
    setProductId: (state, action: PayloadAction<number>) => {
      state.productId = action.payload;
      resetSkuNotFoundState(state);

      if (state.skuEntity && action.payload === NO_VALUE) {
        state.skuEntity = undefined;
        state.skuId = NO_VALUE;
      }
    },
    setStoreId: (state, action: PayloadAction<number>) => {
      state.storeId = action.payload;
      resetSkuNotFoundState(state);

      if (state.skuEntity && action.payload === NO_VALUE) {
        state.skuEntity = undefined;
        state.skuId = NO_VALUE;
      }
    },
    setSkuId: (state, action: PayloadAction<number>) => {
      state.skuId = action.payload;
      resetSkuNotFoundState(state);
    },
    setSkuEntity: (state, action: PayloadAction<SkuAttrsStored>) => {
      state.skuEntity = action.payload;
      state.skuId = action.payload.id;
      resetSkuNotFoundState(state);

      if (action.payload.values.WarehouseId) {
        const newStoreId = parseInt(action.payload.values.WarehouseId);
        if (newStoreId !== state.storeId) {
          state.storeId = newStoreId;
        }
      }

      if (action.payload.values.ProductId) {
        const newProductId = parseInt(action.payload.values.ProductId);
        if (newProductId !== state.productId) {
          state.productId = newProductId;
        }
      }
    },
    setRegionId: (state, action: PayloadAction<number>) => {
      state.regionId = action.payload;
    },
    setIsLoadingSalesData: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSalesData = action.payload;
    },
    skuNotFound: (state, action: PayloadAction<number | undefined>) => {
      state.skuNotFound = true;
      state.skuSearchId = action.payload;
      state.skuEntity = undefined;
      state.skuId = NO_VALUE;
      state.isDetailBusy = false;
      state.isLoadingSalesData = false;
    },
    skuFound: (state, action: PayloadAction<SkuResponse>) => {
      return {
        ...initialState,
        chartSettings: state.chartSettings,
        productId: action.payload.productId,
        storeId: action.payload.storeId,
        skuId: action.payload.skuId,
        skuEntity: makeSKUAttributesStorable(
          parseSkuAttributes(action.payload.skuId, action.payload.attributes),
        ),
        isLoadingSalesData: true,
        isDetailBusy: false,
      };
    },
    resetSkuNotFound: state => {
      resetSkuNotFoundState(state);
    },
    resetSku: state => {
      state.skuId = NO_VALUE;
      state.skuEntity = undefined;
    },
    setChartUnit: (state, action: PayloadAction<Units>) => {
      state.chartSettings.unit = action.payload;
    },
    setSkuChartUnit: (state, action: PayloadAction<Units>) => {
      state.chartSettings.skuChartUnit = action.payload;
    },
    setChartShowCumulativeForecast: (state, action: PayloadAction<boolean>) => {
      state.chartSettings.showCumulativeForecast = action.payload;
    },
    setChartForecastConfidence: (state, action: PayloadAction<number[]>) => {
      state.chartSettings.forecastConfidence = action.payload;
    },
    setDetailBusy: (state, action: PayloadAction<boolean>) => {
      state.isDetailBusy = action.payload;
    },
    resetStateWithBusy: (state, action: PayloadAction<boolean>) => {
      /* allows to reset state in one step and optionally set/reset the busy flags */
      /* so it is not necessary to set them one by one and get inconsistent state in UI */
      return {
        ...initialState,
        isLoadingSalesData: action.payload,
        isDetailBusy: action.payload,
      };
    },
    resetBusyStates: state => {
      state.isDetailBusy = false;
      state.isLoadingSalesData = false;
    },
    resetState: () => initialState,
  },
});

export const {
  setProductId,
  setRegionId,
  setStoreId,
  setSkuEntity,
  setSkuId,
  setIsLoadingSalesData,
  skuNotFound,
  skuFound,
  setChartUnit,
  setSkuChartUnit,
  setChartShowCumulativeForecast,
  setChartForecastConfidence,
  resetSkuNotFound,
  resetSku,
  setDetailBusy,
  resetStateWithBusy,
  resetBusyStates,
} = detailSlice.actions;

export const detailReducer = detailSlice.reducer;
