/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/consistent-type-assertions */
import { generate } from '@ant-design/colors';
import { css } from 'styled-components';
import { CSSProperties } from 'react';

const styleScrollbar = css`
  & {
    scrollbar-color: initial;
  }

  &::-webkit-scrollbar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`.toString();

/* https://ant.design/docs/spec/colors */
const primaryColors = generate('#f29718');

//: Record<string, CSSProperties['color'] | CSSProperties['color'][] | undefined>
export const COLORS = {
  PRIMARY: primaryColors[5],
  PRIMARY_LIGHT: primaryColors[0],
  PRIMARY_DARK: primaryColors[8],
  PRIMARY_STEPS: primaryColors,
  WHITE: 'white',
  HOVER_GREY_LIGHT: '#eeeeee',
  GREY: 'grey',
  GREY_BORDER: '#f4f4f4',
  GREY_LIGHT: '#fafafa',
  GREY_MEDIUM: 'rgba(255, 255, 255, .2)' as CSSProperties['color'],
  GREY_MEDIUM2: 'rgba(0, 0, 0, .05)' as CSSProperties['color'],
  COLLAPSOR_COLOR: 'white' as CSSProperties['color'],
  GREY_DARK: 'darkgrey',
  BACKGROUND: '#fff',
  BACKGROUND_MENU: '#FFFFFF',
  BORDER: 'rgba(212, 212, 213, 1)',
  BORDER_DARK: 'rgba(255, 255, 255, 0.15)',
  BLUE: '#3B5998',
  COLOR_DARK: '#1d1b25',
  MENU_DARK_BACKGROUND: '#1d1b25',
  MENU_DARK_BACKGROUND_SELECTED: '#34323b',
  MENU_DARK_BACKGROUND_HOVER: '#34323b',
  MENU_DARK_BACKGROUND_STEPS: generate('#1d1b25'),
  MENU_TEXT_COLOR: 'rgba(255, 255, 255, .7)' as CSSProperties['color'],
  WEB_ORANGE: '#f1ab3e',
  WEB_ORANGE_HOVER: '#ce8717',
  DANGER: 'rgb(232, 89, 86)' as CSSProperties['color'],
  SUCCESS: 'green' as CSSProperties['color'],

  TABLE_HIGHLIGHT_GREEN: '#edf6ed',
  TABLE_HIGHLIGHT_RED: 'rgb(255 0 0 / 15%)',
  TABLE_HIGHLIGHT_BLUE: '#ceecfd',
  TABLE_HIGHLIGHT_ORANGE: '#faefe2',
  TABLE_HIGHLIGHT_GREY: '#e8e8e8',

  BAR_GREY: 'rgb(186, 186, 186)' as CSSProperties['color'],
  BAR_DEADSTOCK: '#F08583' as CSSProperties['color'],
  BAR_DEADSTOCK_FADED: '#FCF2F5' as CSSProperties['color'],
  BAR_SLOWMOVERS: '#FBC16F' as CSSProperties['color'],
  BAR_SLOWMOVERS_FADED: '#FCF7F3' as CSSProperties['color'],
  BAR_FASTMOVERS: '#8FD2F1' as CSSProperties['color'],
  BAR_FASTMOVERS_FADED: '#F2F9FF' as CSSProperties['color'],
  BAR_HEALTHY: '#E1F2CC' as CSSProperties['color'],
  BAR_OUTGOING: '#dcdcdc' as CSSProperties['color'],
  BAR_FORCED: '#edc5e8' as CSSProperties['color'],
  BAR_IS_CLOSING: '#e89fe1' as CSSProperties['color'],
  BAR_THEORETICAL: '#87B65F' as CSSProperties['color'],
  BAR_TARGET_PARTIAL_SOURCE: '#ABCD90' as CSSProperties['color'],
  BAR_TARGET_NO_SOURCE: '#E1F2CC' as CSSProperties['color'],

  PAGE_BACKGROUND: '#f5f5f5',
  DEADSTOCK_BACKGROUND: 'rgb(252, 242, 245)' as CSSProperties['color'],
  DEADSTOCK_COLOR: 'rgb(232, 89, 86)' as CSSProperties['color'],
  DEADSTOCK_COLOR_FADED: 'rgba(232, 89, 86, .7)' as CSSProperties['color'],
  SLOWMOVER_BACKGROUND: 'rgb(252, 247, 243)' as CSSProperties['color'],
  SLOWMOVER_COLOR: 'rgb(244, 168, 61)' as CSSProperties['color'],
  SLOWMOVER_COLOR_FADED: 'rgba(244, 168, 61, .7)' as CSSProperties['color'],
  FASTMOVER_BACKGROUND: 'rgb(242, 249, 255)' as CSSProperties['color'],
  FASTMOVER_COLOR: 'rgb(65, 192, 251)' as CSSProperties['color'],
  FASTMOVER_COLOR_FADED: 'rgb(65, 192, 251, .7)' as CSSProperties['color'],
  ALL_SKU_TYPES_COLOR: 'rgb(59, 89, 152)' as CSSProperties['color'],

  REBRAND_ORANGE: '#F49A00',
  REBRAND_DARK: '#2d1e1c',
  REBRAND_WEBBG: '#EFEBE5',
};

const BASE_FONT_SIZE = 13;

export const computeRemSize = (pixelSize: number): string =>
  `${Math.round((pixelSize / BASE_FONT_SIZE) * 1000) / 1000}rem`;

export const STYLE = {
  //
  BASE_FONT_SIZE: BASE_FONT_SIZE,
  PX: 1 / BASE_FONT_SIZE,

  //restyling ant design components
  PRIMARY_COLOR: COLORS.PRIMARY,
  COLLAPSOR_HEIGHT: `3rem`,
  LEFT_MENU_COLLAPSED_WIDTH: `3rem`,
  TOP_MENU_HEIGHT: `3rem`,
  TOP_HEADER_ROW_HEIGHT: `3rem`,
  ROW_DIVIDER_COLOR: COLORS.GREY_BORDER,
  DEFAULT_INPUT_WIDTH: computeRemSize(100),
  BORDER_RADIUS: 5,

  GLOBAL_PADDING: '0.5rem',

  //other
  BASE_SHADOW: `0 0 10px 2px rgba(0,0,0,0.3)`,
  SCROLLBAR: styleScrollbar,
  UNIT: 0.5,
  COLUMN_SIZE_IN_REM: (1502 - 10 * 12) / 16 / 12, // (BASE WIDTH - (uncollapsed left menu)) / REM SIZE IN PIXELS / COLUMN COUNT

  //fontsizes
  TEXT_FONT_SIZE: {
    xSmall: computeRemSize(10),
    small: computeRemSize(12),
    normal: '1rem',
    large: computeRemSize(14),
    xLarge: computeRemSize(18),
    xxLarge: computeRemSize(24),
  },
  TITLE_FONT_SIZE: {
    xSmall: computeRemSize(12),
    small: computeRemSize(14),
    normal: computeRemSize(16),
    large: computeRemSize(18),
    xLarge: computeRemSize(28),
    xxLarge: computeRemSize(40),
  },
};
