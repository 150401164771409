import { SkuAttrs } from './skuTypes';
import { SkuAttributeResponse } from '@ydistri/api-sdk';
import { isValidDate } from '../utils/utils';

/**
 * Given a textual representation of a date, return a Date object if the
 * date is other than start of the epoch.
 * @param txtDate
 */
const getValidDate = (txtDate: string | undefined | null): Date | undefined => {
  if (txtDate) {
    const date = new Date(txtDate);
    if (isValidDate(date)) {
      return date;
    }
  }
};

/**
 * Convert an array of type-value pairs to a single object where
 * each type is a property and the value is the value of that property.
 * @param skuId ID of the SKU the attributes belong to
 * @param attributes the attributes to convert
 * @return the converted attributes in attr property
 */
export const parseSkuAttributes = (
  skuId: number,
  attributes: SkuAttributeResponse[] | null | undefined,
): SkuAttrs => {
  const sku: SkuAttrs = {
    id: skuId,
    values: {},
    dates: {},
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- attributes can be null
  if (attributes) {
    attributes.forEach(item => (sku.values[item.type] = item.value));

    sku.dates.dateOfFirstPurchase = getValidDate(sku.values.DateOfFirstPurchase);
    sku.dates.dateOfFirstSale = getValidDate(sku.values.DateOfFirstSale);

    sku.dates.dateOfLastPurchase = getValidDate(sku.values.DateOfLastPurchase);
    sku.dates.dateOfLastSale = getValidDate(sku.values.DateOfLastSale);
  }

  return sku;
};
