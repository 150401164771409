import React, { ReactNode } from 'react';
import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @ydistri/react/use-app-dispatch -- we use useDispatch to assign it to useAppDispatch
import { Provider, useDispatch } from 'react-redux';
import { menuSlice } from './menuSlice';
import { userSlice } from './userSlice';
import { apiSlice } from '../apis/api';
import { categoryTreeSlice } from '../components/global/CategoryTree/categoryTreeSlice';
import { routerSlice } from '../routes/routerSlice';
import { modalSlice } from '../screens/Modals/modalSlice';
import { toastSlice } from '@ydistri/utils';
import { requestListAdministrationSlice } from '../screens/ProjectAdministration/RequestLists/requestListAdministrationSlice';
import { productListAdministrationSlice } from '../screens/ProjectAdministration/ProductLists/productListAdministrationSlice';
import { calculationsSlice } from '../screens/Calculations/calculationsSlice';
import { productListConfigurationSlice } from '../screens/Configuration/ConfigurationProductLists/productListsConfigurationSlice';
import { brandConfigurationSlice } from '../screens/Configuration/ConfigurationBrands/brandsConfigurationSlice';
import { statisticsSlice } from '../screens/CalculationDetail/Statistics/statisticsSlice';
import { detailSlice } from '../screens/Detail/detailSlice';
import { appSlice } from './appSlice';
import { validatorSlice } from '../screens/Configuration/Validator/validatorSlice';
import { scopeSlice } from '../screens/Configuration/ConfigurationStoresAndDepartments/scopeConfigurationSlice';
import { evaluationSlice } from '../screens/CalculationDetail/Evaluation/evaluationSlice';
import { calculationsActionSlice } from '../screens/Modals/CalculationsActionModal/calculationsActionSlice';
import { entityListAdministrationSlice } from '../screens/ProjectAdministration/EntityListAdministration/entityListAdministrationSlice';

const slicesArray = [
  appSlice,
  menuSlice,
  userSlice,
  categoryTreeSlice,
  routerSlice,
  toastSlice,
  detailSlice,
  modalSlice,
  calculationsActionSlice,
  productListConfigurationSlice,
  brandConfigurationSlice,
  requestListAdministrationSlice,
  productListAdministrationSlice,
  entityListAdministrationSlice,
  calculationsSlice,
  statisticsSlice,
  evaluationSlice,
  validatorSlice,
  scopeSlice,
] as const;

export const resetAllSlices = (): void => {
  slicesArray.forEach(slice => {
    store.dispatch(slice.actions.resetState());
  });
};

type SlicesUnion = (typeof slicesArray)[number];
type ReducersObject = {
  [K in SlicesUnion['name']]: Extract<SlicesUnion, { name: K }>['reducer'];
};

export const allReducers: ReducersObject = {
  ...slicesArray.reduce((acc, slice) => {
    //@ts-expect-error different reducers used here
    acc[slice.name] = slice.reducer;
    return acc;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need empty object to start with
  }, {} as ReducersObject),
};

export const store = configureStore({
  reducer: {
    ...allReducers,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // immutableCheck: false,
      // serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export type ReduxState = ReturnType<typeof store.getState>;
export type ReduxDispatch = typeof store.dispatch;
export type StoreAction<R> = ThunkAction<Promise<R>, ReduxState, unknown, AnyAction>;

type DispatchFunc = () => ReduxDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;

export const StoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
