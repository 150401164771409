import { Popover } from 'antd';
import { COLORS, Panel, Text } from '@ydistri/ds';
import React, { CSSProperties } from 'react';
import { styled } from 'styled-components';

export type OutlierPanelColorVariant = 'red' | 'orange' | 'grey';

export const outlierPanelColors: Record<
  OutlierPanelColorVariant,
  {
    background: CSSProperties['backgroundColor'];
    border: CSSProperties['borderColor'];
    text: CSSProperties['color'];
  }
> = {
  red: {
    background: COLORS.TABLE_HIGHLIGHT_RED,
    border: COLORS.DANGER,
    text: COLORS.DANGER,
  },
  orange: {
    background: COLORS.TABLE_HIGHLIGHT_ORANGE,
    border: COLORS.WEB_ORANGE,
    text: COLORS.WEB_ORANGE,
  },
  grey: {
    background: COLORS.GREY_LIGHT,
    border: COLORS.GREY_DARK,
    text: COLORS.GREY_DARK,
  },
};

const OutlierPanel = styled(Panel)<{ $center?: boolean; $colorVariant?: OutlierPanelColorVariant }>`
  background-color: ${p =>
    p.$colorVariant ? outlierPanelColors[p.$colorVariant].background : 'default'};
  border: 1px solid
    ${p => (p.$colorVariant ? outlierPanelColors[p.$colorVariant].border : 'default')};
  color: ${p => (p.$colorVariant ? outlierPanelColors[p.$colorVariant].text : 'default')};
  ${p =>
    p.$center ? `justify-content: center;` : `justify-content: flex-end; padding-right: 0.25rem;`}
`;

interface OutlierValueProps {
  value: number | string | React.ReactNode;
  tooltip?: string | React.ReactNode;
  center?: boolean;
  colorVariant?: OutlierPanelColorVariant;
}

export const OutlierValue: React.FC<OutlierValueProps> = ({
  value,
  tooltip,
  center,
  colorVariant = 'red',
}) => {
  if (tooltip) {
    return (
      <Popover content={tooltip} trigger="hover" placement="topLeft">
        <OutlierPanel $center={center} $colorVariant={colorVariant}>
          <Text $bold={true}>{value}</Text>
        </OutlierPanel>
      </Popover>
    );
  }

  return (
    <OutlierPanel $center={center} $colorVariant={colorVariant}>
      <Text $bold={true}>{value}</Text>
    </OutlierPanel>
  );
};
